@import "../../styles/variables";

.slider-form-group {
  position: relative;
  display: inline-block;
  left: -10px;
  width: calc(100% - 10px);
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 10px solid #bfbfbf;
    border-left: 10px solid transparent;
    left: 0;
    top: -10px;
  }
}

.slider-form-block {
  background: #fff;
  border: 1px solid #f0f0f1;
  box-shadow: 1px 1px 7px #a09e9e;
  overflow: hidden;
  padding: 15px 15px 15px 25px;
  width: 665px;
  border-radius: 0 12px 12px 0;
  transition: all 0.5s;
  position: relative;
  .promo-lable {
    background: $text-accent2;
    color: #fff;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    padding: 25px 40px 8px;
    position: absolute;
    right: -49px;
    top: -9px;
    transform: rotate(45deg);
    .persents {
      font-size: em(17);
      margin-bottom: 0;
    }
  }
  .slider-form {
    position: relative;
    overflow: hidden;
    width: 100%;
    transition: all 0.5s;
    margin-top: 25px;
  }
  .slide {
    padding: 15px;
    width: 100%;
    position: absolute;
    left: 100%;
    top: 0px;
    text-align: center;
    transition: all 0.5s;
    &.shown {
      left: 0;
    }
    &.prev {
      left: -100%;
    }
    &.callback-form {
      position: absolute;
      left: 0;
      top: 0;
      visibility: hidden;
      opacity: 0;
      transition: all 0.1s;
      padding: 15px 80px;
      &.last .title {
        padding: 25px 80px;
      }
      &.show {
        position: relative;
        opacity: 1;
        visibility: visible;
        transition: all 1.5s;
      }
    }
    .title {
      font-size: em(22);
      font-weight: 400;
      color: $text-default;
      max-width: 500px;
      margin: auto;
    }
    .title-sm {
      text-align: left;
      padding-left: 15px;
    }
    .slide-options {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin-top: 26px;
      li {
        width: 28%;
        margin: 2%;
      }
      .option {
        width: 100%;
        border: 1px solid #cbcbcb;
        border-bottom: 6px solid #cbcbcb;
        border-radius: 12px;
        text-align: center;
        background: #f2f2f2;
        padding: 15px;
        min-height: 144px;
        transition: all 0.3s;
        outline: none !important;
        cursor: pointer;
        height: 100%;
        position: relative;
        padding-top: 70px;
        .icon {
          position: absolute;
          left: 50%;
          top: 10px;
          transform: translateX(-50%);
          max-width: 60px;
          margin: 0;
        }
        &:hover {
          border-bottom: 6px solid $text-accent2;
        }
        &.active {
          border-bottom: 6px solid $text-accent2;
        }
        img {
          margin: 15px;
        }
      }
    }
  }
  .control-btns {
    display: flex;
    justify-content: center;
  }
  .btn-primary {
    background: $btn-primary;
    padding: 10px 10px;
    border-radius: 20px;
    color: #fff;
    min-width: 155px;
    outline: none !important;
    font-weight: bold;
    font-size: em(16);
    position: relative;
    margin: 0 5px;
    cursor: pointer;
    &.next {
      padding-right: 35px;
    }
    &.prev {
      padding-left: 37px;
    }
    &.next,
    &.prev {
      position: relative;
      &:before {
        position: absolute;
        width: 34px;
        height: 34px;
        top: 2px;
        right: 3px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        content: "\2192";
      }
    }
    &.prev:before {
      right: auto;
      left: 3px;
      content: "\2190";
    }
  }
}

.column-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  .form-group {
    padding: 0;
    &:first-child {
      margin-right: 5px;
    }
    &:nth-child(2) {
      margin-left: 5px;
    }
  }
}
.form-group {
  padding: 5px;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  > div:first-child {
    width: 100%;
  }
  &.radio {
    > div:first-child {
      width: auto;
    }
  }
  .info-mes {
    color: #b8b8b8;
    font-style: italic;
    float: right;
  }
  .error {
    color: red;
    font-size: em(12);
    text-align: left;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in;
    &.show {
      max-height: 100px;
      overflow: visible;
    }
  }
  .placeholder {
    position: absolute;
    left: 15px;
    top: 20px;
    height: 0;
    color: #b8b8b8;
    font-size: em(14);
    transition: all 0.5s;
  }
  .active {
    .placeholder {
      font-size: em(10);
      top: 10px;
      z-index: 3;
    }
  }
  .check-group {
    display: inline-block;
    margin-right: 15px;
    input {
      display: none;
    }
    &.not-valid {
      label {
        color: red;
        &:before {
          border: 1px solid red;
        }
      }
    }
    input[type="checkbox"]:checked + label:before,
    input[type="radio"]:checked + label:before {
      background: $btn-primary;
    }
    label {
      position: relative;
      cursor: pointer;
      color: #b8b8b8;
      &:before {
        width: 20px;
        height: 20px;
        border: 1px solid #b8b8b8;
        border-radius: 3px;
        display: flex;
        content: "\2713";
        justify-content: center;
        align-content: center;
        color: #fff;
        float: left;
        margin-right: 15px;
      }
    }
  }
  input {
    padding: 20px 10px 10px;
    width: 100%;
    outline: none !important;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    z-index: 2;
    position: relative;
    background: none;
    &.not-valid {
      border: 1px solid red;
    }
    &.valid {
      border: 1px solid green;
    }
  }
  textarea {
    margin-top: 26px;
    margin-bottom: 2px;
    padding: 10px;
    width: 100%;
    height: 120px;
    resize: none;
    border: 1px solid #b8b8b8;
  }
}

.form-information {
  background: #e4ebe9;
  text-align: left;
  padding: 5px;
  display: flex;
  color: #929393;
  font-size: em(12);
  margin: 15px 0;
  .icon {
    position: relative;
    color: #fff;
    background: $btn-primary;
    border-radius: 50%;
    min-width: 25px;
    width: 25px;
    height: 25px;
    margin-right: 25px;
    span {
      font-size: em(22);
      line-height: 12px;
      position: absolute;
      left: 50%;
      top: 47%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.btn-submit {
  margin-top: 15px;
  background: $text-accent2;
  color: #fff;
  font-weight: 600;
  padding: 10px;
  width: 100%;
  font-size: em(14);
  .text-md {
    font-size: em(22);
    display: block;
    text-align: center;
  }
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .benifits-list {
    padding-left: 20px;
    text-align: left;
    flex: 1;
    font-size: em(11);
  }
}

.hint-block {
  position: relative;
  padding: 0 15px;
  &:hover {
    .info {
      display: block;
    }
  }
  .button-hint {
    color: #fff;
    background: #e1ebe9;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    max-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info {
    position: absolute;
    right: 25px;
    bottom: 10px;
    min-width: 250px;
    background: #fff;
    z-index: 10;
    padding: 10px;
    border: 1px solid #d8cfc8;
    border-radius: 12px;
    display: none;
    h6 {
      margin: 5px 0;
    }
    p {
      font-size: em(10);
    }
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b8b8b8;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #b8b8b8;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #b8b8b8;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #b8b8b8;
}

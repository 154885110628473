$width: 1183px;
$font-size: 14px;
$line-height: 16px;

$btn-primary: #3a68b3;

$text-default: #494949;
$text-lighter: #959595;
$text-accent: #3a68b3;
$text-accent2: #f19e28;
$text-grey: #605e5e;
$text-white: #ffffff;

$border-grey: #ededed;

$browser-context: 14; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@media all and (max-width: 1183px) {
  .top-header {
    padding: 0 15px;
  }
  .main-header {
    padding-bottom: 15px;
    flex: auto;
  }
  .slider-form-group {
    width: 100%;
    left: 0;
    &:before {
      display: none;
    }
  }
  .banner-block .banner-main-content {
    background-size: 170%;
    background-position: top;
  }
}

@media all and (max-width: 1153px) {
  .slider-form-group {
    width: calc(100% + 10px);
  }
}

@media all and (max-width: 1024px) {
  .footer-navigation-block {
    display: block;
    margin-bottom: 25px;
  }
  .footer-navigation-block .footer-navigation {
    margin-bottom: 0;
  }
  .footer-navigation-block .right-links {
    max-width: 100%;
  }
  .footer-navigation {
    margin-bottom: 25px;
    .nav {
      max-width: 80%;
      flex-wrap: wrap;
      justify-content: center;
      margin: auto;
      li {
        flex: none;
        margin-bottom: 10px;
        a {
          font-size: em(14);
        }
      }
    }
  }
  .menu-button {
    display: block;
  }
  .main-header {
    flex: auto;
    &.fixed {
      padding-right: 70px;
    }
  }
  .header-block.fixed {
    position: fixed;
    z-index: 12;
    right: 15px;
    top: 25px;
    .navigation {
      z-index: 10;
      top: 0;
      top: 27px;
      position: fixed;
      right: 0;
    }
    .navigation.show .main-nav {
      margin-top: -30px;
      right: 0;
      width: 200px;
    }
  }
  .header-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .slider-form-group {
    width: 100%;
    left: 0;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
  }
  .main-header.fixed {
    padding-left: 15px;
    box-sizing: border-box;
  }
  .top-header {
    flex: auto;
  }
  .navigation {
    display: none;
    top: -500px;
    transition: top 0.5s;
    &.show {
      .main-nav {
        margin-top: 0;
        transition: max-height 0.5s, opacity 0.5s;
        max-height: 1000px;
        opacity: 1;
        overflow: visible;
        width: 200px;
      }
      .menu-button {
        background: transparent;
        &:before {
          transform: rotate(130deg);
          top: 0px;
        }
        &:after {
          transform: rotate(-130deg);
          top: 0px;
        }
      }
    }
  }
  .main-nav {
    position: absolute;
    top: 72px;
    right: 0;
    width: 100%;
    max-width: 200px;
    z-index: 12;
    display: block;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    li {
      border: none;
      a {
        padding: 15px;
        border-bottom: 1px solid #5974cb;
      }
    }
  }
}

@media all and (max-width: 915px) {
  .banner-block .benifits-block {
    font-size: em(18);
  }
}

@media all and (max-width: 700px) {
  .info-links-block .info-links-item:last-child {
    display: none;
  }
}

@media all and (max-width: 900px) {
  .banner-block .benifits-block {
    font-size: em(14);
  }
  .banner-block .banner-main-content {
    background-size: 100%;
  }
  .slider-form-block {
    width: 100%;
  }
  .banner-block .banner-title {
    margin-bottom: 20px;
  }
  .banner-block .banner-main-content {
    padding-bottom: 0;
  }
  .banner-block .benifits-block {
    top: 2px;
  }
  .section.attemtion-points-block .content {
    flex-wrap: wrap;
  }
  section.info-block .content {
    display: block;
  }
  section.attemtion-points-block .content {
    flex-wrap: wrap;
    div {
      width: 100%;
    }
  }
  section .point-list {
    width: 100vw;
    order: 2;
  }
  section .point-list li {
    float: left;
    width: 50%;
  }
  section.fac-block .faq-block-items {
    display: block;
  }
  .footer .copyrights {
    text-align: center;
  }
  section.reviews-block .reviews-list li {
    position: absolute;
    left: 100%;
    top: 0px;
    font-size: em(19);
    max-width: 100%;
    width: 100%;
    transition: all 0.5s;
  }
  section.reviews-block .reviews-list {
    position: relative;
    min-height: 250px;
    overflow: hidden;
    display: block;
  }
  section.reviews-block .control-btns {
    display: block;
  }
  section.reviews-block .reviews-list li header {
    position: relative;
    font-size: em(20);
  }
  section.reviews-block .reviews-list li.active {
    left: 0;
    position: relative;
  }
  section.reviews-block .reviews-list li.prev {
    left: -100%;
  }
  section.reviews-block .reviews-list li .stars img {
    width: 20px;
    margin-top: 5px;
  }
  section.fac-block .faq-list li {
    padding: 0;
  }
  .banner-block .banner-main-content {
    background-position: 0 -105px;
    background-size: 100%;
  }
  .form-group textarea {
    font-size: 16px;
  }
  .form-group input {
    font-size: 16px;
  }
}

@media all and (max-width: 656px) {
  .banner-block .sale-block {
    font-size: em(12);
    line-height: em(14);
    width: 80px;
    height: 80px;
    .text-lg {
      font-size: em(16);
    }
  }
  .main-header .trust-block {
    display: none;
  }
  .main-header .contacts {
    min-width: auto;
    .icon {
      padding: 10px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      max-width: 40px;
      background: $btn-primary;
      svg {
        fill: #fff;
      }
    }
  }
  section.reviews-block .reviews-list {
    min-height: 350px;
  }
  .banner-block .banner-main-content {
    background-position: 0 -50px;
  }
  .banner-block {
    position: relative;
  }
  .banner-block .banner-title {
    font-size: em(22);
    line-height: em(24);
  }
  .banner-block .benifits-block li {
    font-size: em(11);
  }
  .main-header .contacts .number {
    position: absolute;
    min-width: 245px;
    right: -67px;
    top: 75px;
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s;
    z-index: 15;
    background: #fff;
    display: block;
    &.show {
      max-height: 200px;
    }
  }
  .slider-form-block .slide {
    padding: 0px;
  }
}

@media all and (max-width: 550px) {
  .banner-block .banner-main-content {
    background-position: 0 -60px;
  }
  .slider-form-block .slide.callback-form {
    padding: 5px;
  }
  .banner-block .benifits-block {
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    font-size: em(18);
  }
  .banner-block .benifits-block li .content {
    left: 0;
    transform: none;
  }
  .banner-block .sale-block {
    top: 5px;
    right: 5px;
  }
  .slider-form-block .promo-lable {
    display: none;
  }
  .slider-form-block {
    padding: 5px;
  }
  .slider-form-block .slide .slide-options li {
    min-width: 150px;
  }
  .slider-form-block .slide .slide-options {
    justify-content: center;
  }
  .banner-block .benifits-block {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .banner-block .benifits-block li {
    margin-bottom: 15px;
  }
  section .point-list li {
    float: none;
    width: 100%;
  }
  .banner-block .banner-title {
    max-width: 60%;
    font-size: em(18);
    line-height: em(18);
  }
  .form-group textarea {
    width: 100%;
  }
  section.reviews-block .content {
    padding: 15px;
    margin: 15px;
  }
  section.reviews-block .reviews-list {
    padding-left: 0;
  }
  .info-links-block .info-links-item:nth-child(2) {
    display: none;
  }
}

@media all and (max-width: 450px) {
  .banner-block .banner-main-content {
    background-position: 0 0;
  }
  .logo {
    max-width: 50vw;
  }
}

@media all and (max-width: 420px) {
  .banner-block .benifits-block {
    justify-content: center;
  }
  .banner-block .benifits-block li {
    max-width: 240px;
  }
  .slider-form-block .btn-primary {
    min-width: 130px;
  }
  .footer .bottom-links {
    display: block;
    text-align: center;
    h1 {
      max-width: 100%;
      margin-bottom: 15px;
      text-align: center;
    }
  }
}

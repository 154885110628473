@import "../../../styles/variables";

.info-links-block {
  text-align: center;
  .links-block {
    margin-top: 20px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .info-links-item {
    display: block;
    min-width: 220px;
    padding: 10px;
    cursor: pointer;
    text-align: left;
    span {
      display: block;
      background: $btn-primary;
      color: $text-white;
      padding: 20px 25px;
      margin-top: -3px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      position: relative;
      &::after {
        content: url(../../../images/icons/icon_arrow_right.svg);
        position: absolute;
        right: 25px;
      }
    }
  }
  .all-products {
    color: $btn-primary;
    text-decoration: underline;
    cursor: pointer;
  }
}

@import "./variables";
@import "./responsive";

body {
  font: 12px/14px Poppins;
  font-size: $font-size;
  line-height: $line-height;
  color: $text-default;
}

.wrapper-container {
  max-width: $width;
  margin: auto;
  padding: 0px;
  min-height: 100vh;
  position: relative;
}

.hide {
  display: none;
}

img {
  width: 100%;
  height: auto;
}

section.fac-block .faq-block-items img {
    width: auto;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button {
  border: none;
  background: none;
  outline: none;
}

section.text-section {
  padding-left: 15px;
  padding-right: 15px;
}

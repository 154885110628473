@import "../../styles/variables";

.main-nav {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: space-around;
  background: rgb(59, 106, 181);
  background: -moz-linear-gradient(
    top,
    rgba(59, 106, 181, 1) 0%,
    rgba(69, 117, 195, 1) 50%,
    rgba(59, 106, 181, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(59, 106, 181, 1) 0%,
    rgba(69, 117, 195, 1) 50%,
    rgba(59, 106, 181, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(59, 106, 181, 1) 0%,
    rgba(69, 117, 195, 1) 50%,
    rgba(59, 106, 181, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b6ab5', endColorstr='#3b6ab5',GradientType=0 );
}
.main-nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  .close-menu {
    display: none;
  }
  li {
    border-right: 1px solid #5974cb;
    flex: 1;
    *:last-child {
      border-right: 0;
    }
    a {
      white-space: nowrap;
      display: block;
      width: 100%;
      color: #fff;
      text-decoration: none;
      font-size: em(16);
      width: 100%;
      text-align: center;
      padding: 20px 5%;
      transition: background 0.5s;
      &:hover {
        background: #2868b8;
      }
    }
  }
}

.menu-button {
  width: 40px;
  height: 3px;
  margin: 15px 0 15px 0px;
  border-radius: 3px;
  background: #000;
  position: relative;
  display: block;
  transition: all 0.5s;
  margin-right: 15px;
  &:before,
  &:after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    width: 40px;
    height: 3px;
    border-radius: 3px;
    background: #000;
    transition: all 0.5s;
  }
  &:before {
    top: -12px;
  }
  &:after {
    bottom: -12px;
  }
}

.menu-button {
  display: none;
}

.footer-navigation {
  .nav {
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    margin-bottom: 25px;
    li {
      flex: auto;
      a {
        text-decoration: underline;
        color: $text-lighter;
        font-size: em(14);
        width: 100%;
        text-align: center;
        display: block;
        padding: 0 5px;
      }
    }
  }
}

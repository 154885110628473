@import "../../styles//variables";

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  h1 {
    margin-bottom: 0;
  }
  .copyrights {
    font-size: em(15);
    color: $text-lighter;
    padding: 0 15px;
  }
  .bottom-links {
    background: $btn-primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    button {
      background: none;
      border: none;
      outline: none !important;
      cursor: pointer;
    }
    .logo {
      color: #fff;
      font-weight: 700;
      font-size: em(19);
    }
  }
  .block-right {
    margin-bottom: 30px;
    position: relative;
    max-width: 410px;
    overflow: visible;
    transition: all 0.5s ease-in;
    max-height: 500px;
    float: right;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2547);
    &.hiden {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease-in;
    }
    .content {
      border: 1px $border-grey solid;
      border-radius: 5px;
      display: inline-flex;
      padding: 15px;
    }
    .close {
      position: absolute;
      right: 2px;
      top: 2px;
      border: none;
      outline: none !important;
      color: $text-default;
      cursor: pointer;
      font-size: em(24);
    }
    div {
      padding: 10px;
    }
    .item {
      text-align: center;
    }
    span.blue {
      display: block;
      font-size: 30px;
      color: $text-accent;
      margin-bottom: 5px;
    }
  }
}

.footer-navigation-block {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .footer-navigation {
    flex: auto;
  }
  .right-links {
    max-width: 220px;
    li {
      border-right: 1px solid $text-lighter;
      &:last-child {
        border-right: none;
      }
    }
  }
}

@import "../../../styles/variables";

section {
  padding-top: 50px;
  &.fac-block {
    .content {
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
    }
    .faq-block-items {
      display: flex;
      justify-content: space-between;
    }
    .faq-list {
      list-style: none;
      padding: 15px;
      li {
        padding-right: 33px;
        .tite {
          border: 1px solid #f0f0f0;
          padding: 30px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: em(16);
          font-weight: 600;
          cursor: pointer;
          .icon {
            transition: all 0.2s;
            &.open {
              transform: rotate(180deg);
            }
          }
        }
        .answer {
          font-size: em(13);
          font-weight: 500;
          color: $text-lighter;
          line-height: em(28);
          padding: 15px 30px;
        }
        .answer-block {
          overflow: hidden;
          max-height: 0px;
          transition: all 0.5s ease-in;
          opacity: 0;
          &.show {
            max-height: 600px;
            overflow: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

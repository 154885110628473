@import "../../../styles/variables";

section {
  padding-top: 50px;
  &.attemtion-points-block {
    .content {
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
    }
  }
  .point-list {
    list-style: none;
    font-size: em(16);
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .icon {
        display: block;
        width: 37px;
        min-width: 37px;
        height: 37px;
        position: relative;
        border: 2px solid $btn-primary;
        margin-right: 15px;
        img {
          position: absolute;
          z-index: 2;
          right: -10px;
          top: -5px;
        }
      }
    }
  }
}

@import "../../../styles/variables";

section {
  padding-top: 50px;
  &.info-block {
    .content {
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
      p {
        flex: 1;
        padding: 0 15px;
        font-size: em(17);
        line-height: em(22);
        color: $text-lighter;
        text-align: justify;
      }
    }
  }
}

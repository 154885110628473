@import "../../../styles/variables";

section {
  &.reviews-block {
    padding-top: 0px;
    margin-bottom: -50px;
    .content {
      margin: 35px 35px 0;
      background: rgba(255, 255, 255, 0.94);
      padding: 60px 55px;
      border: 1px solid hsl(0, 0%, 94%);
      border-radius: 12px;
      position: relative;
      z-index: 2;
      .block-title {
        margin-bottom: 60px;
      }
    }
    .reviews-list {
      display: flex;
      justify-content: space-around;
      list-style: none;
      li {
        flex: 1;
        max-width: 250px;
        header {
          position: relative;
          padding: 25px 0 25px 85px;
          display: block;
          span {
            font-size: em(13);
            color: $text-grey;
            line-height: em(14);
            display: block;
            margin-bottom: 5px;
          }
        }
        .avatar {
          border-radius: 50%;
          position: absolute;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
          max-width: 72px;
        }
        .review-text {
          color: $text-grey;
          font-size: em(13);
          line-height: em(22);
        }
        .stars {
          position: relative;
          img {
            width: 14px;
            margin-right: 3px;
          }
          .full {
            position: absolute;
            left: 0px;
            top: 0px;
            margin-right: 3px;
          }
        }
      }
    }
    .control-btns {
      text-align: center;
      display: none;
      .next,
      .prev {
        svg {
          fill: $btn-primary;
          width: 40px;
        }
        &.disabled {
          svg {
            fill: $text-lighter;
          }
        }
      }
      .prev {
        transform: rotate(180deg);
        position: relative;
        top: -3px;
      }
    }
    .banner {
      position: relative;
      top: -100px;
      z-index: 1;
    }
  }
}

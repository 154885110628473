@import "../../../styles/variables";

.banner-block {
  .banner-main-content {
    background: url("../../../images/banner1.jpg") no-repeat;
    padding: 70px 0;
    background-position: right;
    background-size: 100%;
    background-position-y: 0;
    position: relative;
  }
  .banner-title {
    margin-bottom: 3vw;
    margin-left: 5vw;
    font-size: em(30);
    color: $text-default;
    font-weight: 500;
    line-height: 50px;
    float: left;
    max-width: 50%;
    .accent-text {
      font-weight: 700;
      color: $text-accent2;
    }
  }
  .sale-block {
    right: 45px;
    top: 70px;
    position: absolute;
    background: $btn-primary;
    width: 105px;
    height: 106px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: em(15);
    font-weight: 600;
    flex-wrap: wrap;
    padding: 20px;
    text-align: center;
    .text-lg {
      font-size: em(31);
    }
  }
  .benifits {
    background: #f4f4f4;
  }
  .benifits-block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    padding: 15px;
    margin: 0;
    font-size: em(19);
    font-weight: 400;
    position: relative;
    top: -4px;
    li {
      flex: 1;
      .content {
        position: relative;
        padding: 10px 0 10px 40px;
        display: inline-block;
        left: 50%;
        transform: translateX(-50%);
        .icon {
          max-width: 28px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        img {
          margin-right: 15px;
        }
      }
    }
  }
}

@import "../../styles/variables";

.main-header {
  padding-top: 15px;
  transition: top 0.5s;
  top: -500px;
  &.fixed {
    position: fixed;
    width: 100%;
    z-index: 10;
    background: #fff;
    max-width: 1183px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: top 0.5s;
  }
  .top-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo h1 {
    color: $text-default;
    font-size: em(21);
    font-weight: 600;
    line-height: 23px;
  }
  .right-sidebar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contacts {
    display: flex;
    align-items: center;
    min-width: 210px;
    .icon {
      max-width: 25px;
      svg {
        fill: $btn-primary;
      }
    }
    .number {
      margin-left: 15px;
      color: $text-accent;
      font-size: em(20);
    }
  }
  .trust-block {
    margin: 0 15px;
  }
}
